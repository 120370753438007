import { useEffect } from 'react';
import token from '../Constants/Auth';
import MuscleApi from '../Constants/MuscleApi';

const useAddHook = url => {

    const checkLink = (url) => {
        const script = document.createElement('script');

        script.src = url;
        script.async = true;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    }

    useEffect(() => {
        MuscleApi.get("ad-status/Website")
            .then((res) => {
                if (res?.data?.data?.status === 1) {
                    // alert(res?.data?.data?.status)
                    token && url && checkLink(url)
                }
            })
            .catch((error) => { });
    }, [url])

};

export default useAddHook;