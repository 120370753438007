import React from "react";
import DisclaimerScreen from "../Components/Disclaimer/DisclaimerScreen";

const Disclaimer = () => {
    return (
        <>
            <DisclaimerScreen />
        </>
    )
}

export default Disclaimer