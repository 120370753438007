import React, { useState } from "react";
import logo from "../../Assets/Images/logo1.png";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";


import MuscleApi from "../../Constants/MuscleApi";

const Login = () => {
  let navigate = useNavigate();
  const [toggle, setToggle] = useState(false);
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [showA, setShowA] = useState(true);
  const toggleShowA = () => setShowA(!showA);

  const handleLogin = (e) => {
    e.preventDefault();
    const data = {
      email: email,
      password: password,
    };
    MuscleApi.post("login", data)
      .then((res) => {
        if (res.data.status === "success") {
          setEmail("");
          setPassword("");
          localStorage.setItem("token", res.data.access_token);
          navigate("/");
          toast.success("Login Successfully", { duration: 4000 });

        } else {

          toast.error(`${res?.data?.msg}`, { duration: 4000 });

          // alert(`${res?.data?.msg}`);
        }
      })
      .catch((error) => { });

  };
  return (
    <>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-6">
            <div className="card p-3 border-0 bg-light-blue">
              <div className="row justify-content-center">
                <div className="col-md-2">
                  <img src={logo} width="100%" alt="" />
                </div>
              </div>

              <Form onSubmit={handleLogin}>
                <h5 className="text-center text-logo fw-700">Login</h5>
                <p className="text-muted text-center fw-600">
                  Please login to your Account
                </p>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Control
                    type="email"
                    className="border-0"
                    placeholder="Enter email "
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Control
                    onChange={(e) => setPassword(e.target.value)}
                    type="password"
                    className="border-0"
                    placeholder="Password"
                  />
                </Form.Group>

                <Button variant="primary" type="submit" className="w-100">
                  Submit
                </Button>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
