import './App.css';
import './app.scss';
import AppRouting from './Routes/AppRouting';
import toast, { Toaster } from "react-hot-toast";



function App() {


  return (
    <>
      <Toaster position="top-center" reverseOrder={false} />

      <AppRouting />
    </>
  );
}

export default App;
