import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import MuscleApi from "../../Constants/MuscleApi";
import { toast } from 'react-hot-toast';



const NewsletterScreen = () => {

    const [email, setEmail] = useState("")
    const [name, setName] = useState("")


    const handleLogin = (e) => {
        e.preventDefault();
        const data = {
            email: email,
            name: name,
        };
        MuscleApi.post("newsletter", data)
            .then((res) => {
                if (res.data.status === "succ") {
                    setEmail("");
                    setName("");

                    // localStorage.setItem("token", res.data.access_token);
                    // navigate("/");
                    toast.success("Subscribe Successfully", { duration: 4000 });
                    // console.log("res.data.status" ,res.data.status)

                } else {

                    toast.error(`${res?.data?.msg}`, { duration: 4000 });

                    // alert(`${res?.data?.msg}`);
                }
            })
            .catch((error) => { 
                
                // alert(error)
            });

    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (

        <>
            <section className='bg-light-blue '>

                <div className="container">
                    <div className="row">
                        <div className="col-md-12 p-5">
                            <h1>Newsletter</h1>

                            <hr />
                            <div className="col-md-12 bg-white p-5">

                                <h5 className='border-bottom '>Subscribe to our mailing list</h5>
                                {/* <p className='text-end s-14 mb-2' > <span className='text-danger'>* </span> indicates required</p> */}
                                <Form onSubmit={handleLogin}>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>Email Address</Form.Label>
                                        <Form.Control type="email"
                                            onChange={(e) => setEmail(e.target.value)}
                                            value={email}
                                            className="rounder-0" />

                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="formBasicPassword">
                                        <Form.Label>First Name</Form.Label>
                                        <Form.Control className="rounder-0"
                                            onChange={(e) => setName(e.target.value)}
                                            value={name}
                                            type="text" />
                                    </Form.Group>

                                    <Button variant="primary" type="submit">
                                        Submit
                                    </Button>
                                </Form>
                            </div>

                        </div>



                    </div>
                </div>
            </section>

        </>)
}

export default NewsletterScreen