import React from 'react'
import DetailsScreen from "../Components/Exercises/DetailsScreen";

const Details = () => {
  return (
    
    <DetailsScreen />
    
  )
}

export default Details