import React from "react";
import Card from "react-credit-cards";
import axios from "axios";
import { Navigate, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";



import SupportedCards from "./Cards";

import {
  formatCreditCardNumber,
  formatCVC,
  formatExpirationDate,
  formatFormData,
} from "./utils";

import "react-credit-cards/es/styles-compiled.css";
import MuscleApi from "../../Constants/MuscleApi";

export default class App extends React.Component {


  state = {
    number: "",
    name: "",
    email: "",
    phone: "",
    password: "",

    expiry: "",
    cvc: "",
    issuer: "",
    focused: "",
    formData: null,
    replace: false,
  };

  handleCallback = ({ issuer }, isValid) => {
    if (isValid) {
      this.setState({ issuer });
    }
  };

  handleInputFocus = ({ target }) => {
    this.setState({
      focused: target.name,
    });
  };

  handleInputChange = ({ target }) => {
    if (target.name === "number") {
      target.value = formatCreditCardNumber(target.value);
    } else if (target.name === "expiry") {
      target.value = formatExpirationDate(target.value);
    } else if (target.name === "cvc") {
      target.value = formatCVC(target.value);
    }

    this.setState({ [target.name]: target.value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { issuer } = this.state;
    const formData = [...e.target.elements]
      .filter((d) => d.name)
      .reduce((acc, d) => {
        acc[d.name] = d.value;
        return acc;
      }, {});

    const myarr = formData?.expiry.split("/");

    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Content-Type", "application/json");

    const data = {
      name: formData?.name,
      email: formData?.email,
      password: formData?.password,
      phone: formData?.phone,
      card_no: formData?.number,
      cc_expiry_month: myarr[0],
      cc_expiry_year: myarr[1],
      cvc_number: formData?.cvc,
    };

    MuscleApi.post("sign-up-new", data)
      .then((res) => {
        if (res?.data?.status === 'success') {
          toast.success("Registered Successfully", { duration: 4000 });
          const data2 = {
            email: data?.email,
            password: data?.password
          }
          MuscleApi.post("login", data2)
            .then((res) => {
              if (res.data.status === "success") {

                localStorage.setItem("token", res.data.access_token);
                this.setState({ replace: true });
                

                toast.success("Login Successfully", { duration: 4000 });

              } else {

                toast.error(`${res?.data?.msg}`, { duration: 4000 });

                // alert(`${res?.data?.msg}`);
              }
            })
            .catch((error) => { });
        } else {
          toast.error(res?.data?.msg, { duration: 4000 });
        }
      })
      .catch(() => {

      })

    // fetch("https://admin.workoutlibrary.org/api/", {
    //   method: "POST",
    //   headers: myHeaders,
    //   body: JSON.stringify(data),
    // })
    //   .then((res) => res.json())
    //   .then((json) => console.log(json));
    // toast.success("Registered Successfully", { duration: 4000 });


    console.log("object", formData);


    this.setState({ formData });
    this.form.reset();

  };

  render() {
    const { name, number, expiry, cvc, focused, issuer, formData } = this.state;

    return (
      <>
        {this.state.replace && (
          <Navigate to="/" />
        )}
        <div key="Payment">
          <div className="row justify-content-center">
            <div className="col-md-6">
              <div className="card p-3 rounded-4 border-0 bg-light-blue">
                <div className="App-payment">
                  <Card
                    number={number}
                    name={name}
                    expiry={expiry}
                    cvc={cvc}
                    focused={focused}
                    callback={this.handleCallback}
                  />

                  <form
                    className="row mt-5"
                    ref={(c) => (this.form = c)}
                    onSubmit={this.handleSubmit}
                  >
                    <div className="form-group mb-3  col-12">
                      <input
                        type="email"
                        name="email"
                        className="form-control"
                        placeholder="Email"
                        required
                        onChange={this.handleInputChange}
                        onFocus={this.handleInputFocus}
                      />
                    </div>

                    <div className="form-group mb-3  col-12">
                      <input
                        type="password"
                        name="password"
                        className="form-control"
                        placeholder="Password"
                        required
                        onChange={this.handleInputChange}
                        onFocus={this.handleInputFocus}
                      />
                    </div>

                    <div className="form-group mb-3 col-12">
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        placeholder="Name"
                        required
                        onChange={this.handleInputChange}
                        onFocus={this.handleInputFocus}
                      />
                    </div>

                    <div className="form-group mb-3 col-12">
                      <input
                        type="tel"
                        name="phone"
                        className="form-control"
                        placeholder="Phone Number"
                        required
                        onChange={this.handleInputChange}
                        onFocus={this.handleInputFocus}
                      />
                    </div>

                    <div className="form-group col-12">
                      <input
                        type="tel"
                        name="number"
                        className="form-control"
                        placeholder="Card Number"
                        pattern="[\d| ]{16,22}"
                        maxLength="19"
                        required
                        onChange={this.handleInputChange}
                        onFocus={this.handleInputFocus}
                      />
                    </div>

                    <div className="form-group mt-3  col-6">
                      <input
                        type="tel"
                        name="expiry"
                        className="form-control"
                        placeholder="Valid Thru"
                        pattern="\d\d/\d\d"
                        required
                        onChange={this.handleInputChange}
                        onFocus={this.handleInputFocus}
                      />
                    </div>

                    <div className="form-group mt-3 col-6">
                      <input
                        type="tel"
                        name="cvc"
                        className="form-control"
                        placeholder="CVC"
                        pattern="\d{3,4}"
                        required
                        onChange={this.handleInputChange}
                        onFocus={this.handleInputFocus}
                      />
                    </div>

                    <div className="form-group mt-3 col-12">
                      <input type="hidden" name="issuer" value={issuer} />
                      <div className="form-actions">
                        <button
                          type="submit"
                          className="btn btn-primary btn-block w-100"
                        >
                          PAY
                        </button>
                      </div>
                    </div>
                  </form>
                  {/* {formData && (
                  <div className="App-highlight">
                    {formatFormData(formData).map((d, i) => (
                      <div key={i}>{d}</div>
                    ))}
                  </div>
                )} */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
