import React from 'react'
import CopyrightScreen from "../Components/Copyright/CopyrightScreen";

const Copyright = () => {
    return (
        <>
            <CopyrightScreen />
        </>
    )
}

export default Copyright