import React from "react";
import diamond from "../../Assets/Images/diamond.png";
import { Link } from "react-router-dom";
const PremiumScreen = () => {
  return (
    <>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-6">
            <div className="card p-3 border-0 bg-light-blue">
              <div className="row justify-content-center">
                <div className="col-md-2 text-center">
                  <img src={diamond} width="40" alt="" />
                </div>

                <div className="col-12">
                  <h3 className="text-center text-logo">Get Premium</h3>
                  <h3 className="text-center text-logo">Rs 2,773.84</h3>
                  <h4 className="text-center text-logo">Adds Free </h4>
                  <h4 className="text-center text-logo">Fast Performance</h4>

                  <div className="text-center">
                    <Link to={"/signup"} className="btn btn-primary">
                      Premium
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PremiumScreen;
