import React ,{useEffect} from 'react'
import { useLocation } from 'react-router-dom';

const CopyrightScreen = () => {
    let location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    },[]);

    console.log('location', location);
    
    return (
        <>
            <section className='bg-light-blue '>

                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h1>Copyright</h1>

                            <hr />

                            <p>Some content may be used free of charge without prior consent. The .gif files, text, videos which can be found on youtube.com and muscle information
                                can be used with the workoutLibrary branding and with links back to workoutLibrary.com.</p>

                            <p>Content which cannot be redistributed without prior written consent of workoutLibrary includes the front and back human muscle map drawings of both male and female which feature on the homepage, the tools including but not limited to the calculators, .png .jpg .jpeg files. If you wish to discuss using this content, email workoutLibraryapp@gmail.com with your contact information, exactly which content you are interested in presenting and the URL on which it will appear. Once you are granted permission, we will require acknowledgements stating "images/content created by workoutLibrary.com and are used here with permission from workoutLibrary"
                                along with a link to workoutLibrary.com on each pages our images/content appear.</p>



                        </div>

                    </div>
                </div>
            </section>

        </>
    )
}

export default CopyrightScreen