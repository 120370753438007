const token = () => {
    const getToken = localStorage.getItem("token")
    
    if(getToken === null){
        return false
    }

    return true
}

export default token