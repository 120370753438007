import React, { useEffect, useState } from "react";
import { ButtonGroup } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Dropdown from "react-bootstrap/Dropdown";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Placeholder from "react-bootstrap/Placeholder";
import { useDispatch, useSelector } from "react-redux";
import { Link, Outlet, useLocation } from "react-router-dom";
import logo from "../../Assets/Images/logo.png";
import logoWhite from "../../Assets/Images/logoWhite.png";
import user from "../../Assets/Images/user.png";
import token from "../../Constants/Auth";
import { base_url } from "../../Constants/webService";
import { selectCategory, setCategory } from "../../Redux/slices/userSlice";

import { useNavigate } from "react-router-dom";

const Layout = () => {
  let navigate = useNavigate();

  const dispatch = useDispatch();
  let location = useLocation();
  const category = useSelector(selectCategory);
  const [slide, setSlide] = useState(false);
  const [categories, setCategories] = useState([]);

  const handleSidebar = (val) => {
    const disc = document.getElementsByClassName("disc");
    if (val) {
      setSlide(val);
      for (let i = 0; i <= disc?.length; i++) {
        disc[i].style.display = "none";
      }
    } else {
      setSlide(val);
      for (let i = 0; i <= disc?.length; i++) {
        disc[i].style.display = "";
      }
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    let res = await fetch(`${base_url}/api/categories`, {
      method: "GET",
      headers: new Headers({ "content-type": "application/json" }),
    });
    let response = await res.json();
    setCategories(response?.categories);
    dispatch(setCategory(response?.categories[0]?.id));
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/");
  };

  const getToken = localStorage.getItem("token");

  console.log("getToken", getToken);

  console.log("token", token());

  return (
    <>
      <section className=" top border-bottom-round border-bottom border-dark">
        <div className="row g-0">
          <Navbar bg="white" expand="lg">
            <Container>
              <Navbar.Brand href="javascript:void(0)" className="m-0">
                <Link to="/" className="text-decoration-none text-dark fw-700 align-items-center">
                  <img src={logo} className="logo-image" alt="Workout-Library" height={55} />{" "}
                  {/* <span className="s-28 text-logo d-md-none">
                    {" "}
                    Workout Library{" "}
                  </span> */}
                </Link>
              </Navbar.Brand>

              <Nav className="mx-auto fw-700">
                <span className="s-28 text-logo d-md-none">
                  {" "}
                  Workout Library{" "}
                </span>
              </Nav>

              {/* <Navbar.Collapse className="justify-content-end">
                <Link>
                  Signed in as: <a href="#login">Mark Otto</a>
                </Link>
              </Navbar.Collapse> */}

              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ms-auto d-none d-md-block">
                  <h1 className="text-logo fw-bold" style={{ fontSize: 45 }}>
                    {" "}
                    Workout Library{" "}
                  </h1>
                </Nav>
                <Nav className="ms-auto ">
                  {token() === false ? (
                    <>
                      <Link
                        to={"/login"}
                        eventKey={2}
                        className="fw-600 nav-link s-16"
                      >
                        Login
                      </Link>

                      <Link
                        to={"/get-premium"}
                        eventKey={2}
                        className="nav-link fw-600"
                      >
                        <i className="fa-regular fa-gem s-20" />
                      </Link>
                    </>
                  ) : (
                    <Dropdown as={ButtonGroup} className="dropdown-logout">
                      <Dropdown.Toggle id="dropdown-custom-1">
                        <img
                          alt="/"
                          src={user}
                          className="rounded-circle border"
                          width="32"
                          height="32"
                        />
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="super-colors">
                        <Dropdown.Item eventKey="1">
                          <Link
                            to={"/my-profile"}
                            className="text-decoration-none text-dark"
                          >
                            Profile
                          </Link>
                        </Dropdown.Item>
                        <Dropdown.Item eventKey="2" onClick={handleLogout}>
                          Logout
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  )}
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </div>
      </section>

      <section className="container mt-3 mb-3 d-lg-none ">
        <div className="row g-2 p-2 align-items-center bg-light-blue d-flex flex-nowrap overflow-auto br-10 align-self-stretch">
          {categories?.length > 0 ? (
            <>
              {categories.map((item) => (
                <div
                  key={item?.id}
                  className={`col-2 align-self-stretch ${category == item?.id ? "bg-dark-blue" : ""
                    }  br-10 m-2`}
                  onClick={() => dispatch(setCategory(item?.id))}
                >
                  <div className="row g-0 align-items-center justify-content-center h-100 w-100">
                    <img width="100%" src={item?.image} alt="" />
                  </div>
                </div>
              ))}
            </>
          ) : (
            <>
              <div className={`col-2 text-center align-items-center br-10 m-2`}>
                <Placeholder animation="glow">
                  <Placeholder xs={10} />
                </Placeholder>
              </div>
              <div className={`col-2 text-center align-items-center br-10 m-2`}>
                <Placeholder animation="glow">
                  <Placeholder xs={10} />
                </Placeholder>
              </div>
              <div className={`col-2 text-center align-items-center br-10 m-2`}>
                <Placeholder animation="glow">
                  <Placeholder xs={10} />
                </Placeholder>
              </div>
              <div className={`col-2 text-center align-items-center br-10 m-2`}>
                <Placeholder animation="glow">
                  <Placeholder xs={10} />
                </Placeholder>
              </div>
            </>
          )}
        </div>

        {/* <div className='col-2 col-main text-center  m-2' >
            <img width='100%' src={strech} alt="" />

          </div>
          <div className='col-2 col-main text-center  m-2' >
            <img width='100%' src={images} alt="" />

          </div>
          <div className='col-2 col-main text-center  m-2' >
            <img width='100%' src={barbell} alt="" />

          </div>

          <div className='col-2 col-main text-center  m-2' >
            <img width='100%' src={kettlebell} alt="" />

          </div>
          <div className='col-2 col-main text-center  m-2' >
            <img width='100%' src={band} alt="" />


          </div>

          <div className='col-2 col-main text-center  m-2' >
            <img width='100%' src={cables} alt="" />


          </div> */}

        {/* </div> */}
      </section>

      <section className="container-fluid" style={{ minHeight: "80dvh" }}>
        <div className="row g-2 justify-content-center mb-5 mt-2">
          {location?.pathname != "/disclaimer" &&
            location?.pathname != "/copyright" &&
            location?.pathname != "/privacy-policy" &&
            location?.pathname != "/login" &&
            location?.pathname != "/signup" &&
            location?.pathname != "/get-premium" &&
            location?.pathname != "/my-profile" &&
            location?.pathname != "/newsletter" ? (
            <div
              className={` d-lg-block  d-none ${slide ? "col-md-1" : "col-md-3"
                }`}
            >
              <div className="container top-0 position-sticky">
                <div className="row br-10 bg-light-blue">
                  <div
                    className="col-md-12 style-sidebar example overflow-auto"
                    style={{ height: "700px" }}
                  >
                    <div className="row p-3 ">
                      <div className="">
                        <i
                          className="fas fa-bars s-34  pb-3"
                          onClick={() => handleSidebar(!slide)}
                        ></i>
                      </div>
                      {categories?.length > 0 ? (
                        <>
                          {categories?.map((item, index) => (
                            <div
                              key={item?.id}
                              className={`col-md-12 mb-3 card-shadow ${category == item?.id ? "bg-dark" : "bg-blue"
                                }  br-10 p-3`}
                              onClick={() => dispatch(setCategory(item?.id))}
                            >
                              <div className="row">
                                <div id="" className="col-md-8 disc">
                                  <h5 className="text-uppercase text-white">
                                    {item?.name}
                                  </h5>
                                  <p className=" text-white">
                                    {item?.description
                                      ? item?.description
                                      : `Slogan ${item?.name}`}
                                  </p>
                                  <button
                                    type="button"
                                    className=" btn-nav border-0"
                                  >
                                    Start Workout
                                  </button>
                                </div>

                                <div
                                  className={`${slide ? "col-md-12" : "col-md-4"
                                    }`}
                                >
                                  <img width="100%" src={item?.image} alt="" />
                                </div>
                              </div>
                            </div>
                          ))}
                        </>
                      ) : (
                        <>
                          <div className="col-md-12 mb-3 shimmer   br-10 p-3">
                            <div className="row">
                              <div id="" className="col-md-8 disc">
                                <h5>
                                  <Placeholder animation="glow">
                                    <Placeholder xs={10} />
                                  </Placeholder>
                                </h5>
                                <p>
                                  <Placeholder animation="glow">
                                    <Placeholder xs={7} />
                                  </Placeholder>
                                </p>
                                <div type="" className="  border-0">
                                  <Placeholder animation="glow">
                                    <Placeholder as={Card.Image} xs={8} />
                                  </Placeholder>
                                </div>
                              </div>

                              <div
                                className={`${slide ? "col-md-12" : "col-md-4"
                                  }`}
                              >
                                <Placeholder animation="glow">
                                  <Placeholder xs={8} />
                                </Placeholder>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12 mb-3 shimmer   br-10 p-3">
                            <div className="row">
                              <div id="" className="col-md-8 disc">
                                <h5>
                                  <Placeholder animation="glow">
                                    <Placeholder xs={10} />
                                  </Placeholder>
                                </h5>
                                <p>
                                  <Placeholder animation="glow">
                                    <Placeholder xs={7} />
                                  </Placeholder>
                                </p>
                                <div type="" className="  border-0">
                                  <Placeholder animation="glow">
                                    <Placeholder as={Card.Image} xs={8} />
                                  </Placeholder>
                                </div>
                              </div>

                              <div
                                className={`${slide ? "col-md-12" : "col-md-4"
                                  }`}
                              >
                                <Placeholder animation="glow">
                                  <Placeholder xs={8} />
                                </Placeholder>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12 mb-3 shimmer   br-10 p-3">
                            <div className="row">
                              <div id="" className="col-md-8 disc">
                                <h5>
                                  <Placeholder animation="glow">
                                    <Placeholder xs={10} />
                                  </Placeholder>
                                </h5>
                                <p>
                                  <Placeholder animation="glow">
                                    <Placeholder xs={7} />
                                  </Placeholder>
                                </p>
                                <div type="" className="  border-0">
                                  <Placeholder animation="glow">
                                    <Placeholder as={Card.Image} xs={8} />
                                  </Placeholder>
                                </div>
                              </div>

                              <div
                                className={`${slide ? "col-md-12" : "col-md-4"
                                  }`}
                              >
                                <Placeholder animation="glow">
                                  <Placeholder xs={8} />
                                </Placeholder>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12 mb-3 shimmer   br-10 p-3">
                            <div className="row">
                              <div id="" className="col-md-8 disc">
                                <h5>
                                  <Placeholder animation="glow">
                                    <Placeholder xs={10} />
                                  </Placeholder>
                                </h5>
                                <p>
                                  <Placeholder animation="glow">
                                    <Placeholder xs={7} />
                                  </Placeholder>
                                </p>
                                <div type="" className="  border-0">
                                  <Placeholder animation="glow">
                                    <Placeholder as={Card.Image} xs={8} />
                                  </Placeholder>
                                </div>
                              </div>

                              <div
                                className={`${slide ? "col-md-12" : "col-md-4"
                                  }`}
                              >
                                <Placeholder animation="glow">
                                  <Placeholder xs={8} />
                                </Placeholder>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* <ExerciseNav /> */}
            </div>
          ) : (
            <></>
          )}

          <div className={`${slide ? "col-lg-11" : "col-lg-9"} col-md-12`}>
            <Outlet />

            {/* <div className="row justify-content-center">
              <div className="col-md-10  mt-5 text-center">
                <img width="100%" src={Banner} alt="" />
              </div>
            </div> */}
          </div>
        </div>
      </section>

      <section className="footer pt-4 pb-4">
        <div className="container">
          <div className="row g-3 g-md-0 align-items-center">
            <div className="col-md-4">
              <div>

                <Link to="/" className="text-decoration-none">
                  <div className="d-flex align-items-center">
                    <img src={logoWhite} height="65" className="logo-image" alt="" />{" "}
                    &nbsp; &nbsp; <h3 className="text-white text-decoration-none mb-0 fw-700">Workout Library</h3>
                  </div>
                </Link>

                <p>
                  © Copyright 2023 Workout library SEZC. Some Rights Reserved.
                </p>

                <div className="row">
                  <div className="col-auto">
                    <Link
                      className="text-white text-decoration-none"
                      to="/disclaimer"
                    >
                      Disclaimer
                    </Link>
                  </div>

                  <div className="col-auto">
                    <Link
                      className="text-white text-decoration-none"
                      to="/copyright"
                    >
                      Copyright
                    </Link>
                  </div>

                  <div className="col-auto">
                    <Link
                      className="text-white text-decoration-none"
                      to="/newsletter"
                    >
                      Newsletter
                    </Link>
                  </div>

                  <div className="col-auto">
                    <Link
                      className="text-white text-decoration-none"
                      to="/privacy-policy"
                    >
                      Privacy Policy
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4 text-center">
              <div className="row justify-content-center">
                <div className="col-auto">
                  <a href="https://play.google.com/store/apps/details?id=com.workoutlibrary" target="_blank">
                    <i className="fa-brands fa-google-play s-34 me-3 googplay-hover-green"></i>
                  </a>
                </div>

                <div className="col-auto">
                  <a href="https://apps.apple.com/pk/app/workout-library/id1667540156" target="_blank">
                    <i className="fa-brands fa-apple s-34 apple-hover-black"></i>
                  </a>
                </div>
              </div>
            </div>

            <div className="col-md-4 text-center">
              <div className="row justify-content-center">
                <div className="col-auto">
                  <a
                    href="https://instagram.com/workoutlibrary_?igshid=MzRlODBiNWFlZA=="
                    target="_blank"
                  >
                    <i className="fa-brands fa-instagram s-34 me-3 insta-hover-light-red"></i>
                  </a>
                </div>

                <div className="col-auto">
                  <a
                    href="https://www.tiktok.com/@workoutlibrary0?_t=8XZ6V8Vhn8U&_r=1"
                    target="_blank"
                  >
                    <i className="fa-brands fa-tiktok s-34 me-3 tiktok-hover-light-blue"></i>
                    {/* <i className="fa-brands fa-tiktok "></i> */}
                  </a>
                </div>

                <div className="col-auto">
                  <a
                    href="https://m.facebook.com/people/Workout-library/100063572831360/"
                    target="_blank"
                  >
                    <i className="fa-brands fa-facebook s-34 me-3 fb-hover-blue"></i>
                  </a>
                </div>

                <div className="col-auto">
                  <a
                    href="https://www.youtube.com/channel/UCS4jk6pAVXgj-2jhRuNQbIA"
                    target="_blank"
                  >
                    <i className="fa-brands fa-youtube s-34 me-3 youtube-hover-red"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-md-12">
              <h5>simplify your workout</h5>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Layout;
