import React from 'react'
import PrivacyPolicyScreen from "../Components/PrivacyPolicy/PrivacyPolicyScreen";

const PrivacyPolicy = () => {
    return (
        <>
            <PrivacyPolicyScreen />
        </>
    )
}

export default PrivacyPolicy