import React from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "../Components/Main/Layout";
import Copyright from "../Pages/Copyright";
import Details from "../Pages/Details";
import Disclaimer from "../Pages/Disclaimer";
import Home from "../Pages/Home";
import Login from '../Pages/Login';
import Newsletter from "../Pages/Newsletter";
import Premium from '../Pages/Premium';
import PrivacyPolicy from '../Pages/PrivacyPolicy';
import Profile from '../Pages/Profile';
import SignUp from '../Pages/SignUp';





const AppRouting = () => {
  return (

    <>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Layout />}>
            <Route index element={<Home />} />
            <Route path='/details' element={<Details />} />
            <Route path='/disclaimer' element={<Disclaimer />} />
            <Route path='/copyright' element={<Copyright />} />
            <Route path='/newsletter' element={<Newsletter />} />
            <Route path='/privacy-policy' element={<PrivacyPolicy />} />
            <Route path='/login' element={<Login />} />
            <Route path='/signup' element={<SignUp />} />
            <Route path='/get-premium' element={<Premium />} />
            <Route path='/my-profile' element={<Profile/>} />



          </Route>
        </Routes>
      </BrowserRouter>
    </>
  )
}

export default AppRouting
