import React from 'react'
import NewsletterScreen from "../Components/Newsletter/NewsletterScreen";

const Newsletter = () => {
    return (
        <>
            <NewsletterScreen />
        </>
    )
}

export default Newsletter