import React ,{useEffect} from "react";

const DisclaimerScreen = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    },[]);

    return (
        <>
            <section className="bg-light-blue ">

                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h1>Disclaimer</h1>

                            <hr />

                            <p>By using workoutLibrary.org you agree that nothing contained on the website should be considered as, or used as a substitute for, medical advice, diagnosis or treatment.</p>

                            <p>Additionally you agree that you are aware that some of the activities
                                demonstrated on this site involve certain risks including but not limited to heart attack,
                                stroke, injury to bones, joints, or muscles, serious spinal and neck injuries resulting in complete
                                or partial paralysis. You are voluntarily participating in mentioned activities with full knowledge
                                of the involved dangers and
                                hereby agree to accept any and all inherent risks of injury, property damage or death.</p>


                            <p>workoutLibrary.org accepts no liability for injury resulting from using the site and you agree that workoutLibrary.org, 
                                its owners and associates are not responsible for any loss of property, injury or death relating to your use of workoutLibrary.org services. 
                                Finally, you agree that workoutLibrary, its owners or associates shall have no liability to you under any interpretation of law,
                                 liability or indemnity in relation with your use of workoutLibrary's Services or Products.</p>

                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default DisclaimerScreen