import React from "react";
import ExerciseNav from "../Components/Exercises/ExerciseNav";
import Index from "../Components/Exercises/Index";

const Home = () => {
  return (
    <>
      <Index />
    </>
  );
};

export default Home;
