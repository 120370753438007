import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import MuscleApi from "../../Constants/MuscleApi";
import user from "../../Assets/Images/profile-picture.jpeg";

const MyProfileScreen = () => {
  const [data, setData] = useState({});
  const [profileImg, setProfileImg] = useState("");

  // const fileHandler = (e) => {
  //   setProfileImg(URL.createObjectURL(e.target.files[0]))
  // }

  const fetchData = () => {
    const access_token = localStorage.getItem("token");
    const config = {
      headers: { Authorization: `Bearer ${access_token}` },
    };
    MuscleApi.get("profile", config)
      .then((res) => {
        // alert(`${res?.data.msg}`);
        setData(res.data.data);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    fetchData();
  }, []);

  console.log("data", data);
  return (
    <>
      <div className="row justify-content-center mt-5">
        <div className="col-md-6 ">
          <div className="card rounded-4 position-relative border-0 p-3 bg-light-blue">
            <div className="position-absolute mb-5 text-center top-0 start-50 translate-middle">
              <img
                src={user}
                width="70px"
                height="70px"
                className="rounded-circle border d-none"
                alt="user"
              />
              {/* <h6>Upload Image</h6> */}
            </div>

            {/* <input type="file" onChange={fileHandler()} /> */}
            {/* <hr /> */}

            <Form className="">
              <Form.Group className="mb-3" controlId="name">
                <Form.Label className="fw-700 s-12">Name</Form.Label>
                <Form.Control
                  type="text"
                  value={data.name}
                  placeholder="Name"
                  disabled
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label className="fw-700 s-12">Email address</Form.Label>
                <Form.Control
                  type="email"
                  value={data.email}
                  placeholder="Enter email"
                  disabled
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="userID">
                <Form.Label className="fw-700 s-12">User Id</Form.Label>
                <Form.Control
                  type="number"
                  value={data.id}
                  placeholder="User Id"
                  disabled
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label className="fw-700 s-12">Phone</Form.Label>
                <Form.Control
                  type="tel"
                  value={data.phone}
                  placeholder="Phone"
                  disabled
                />
              </Form.Group>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyProfileScreen;
