import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Pagination from "react-bootstrap/Pagination";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useAddHook from "../../Add/useAddHooks";
import { base_url, imagesExtention } from "../../Constants/webService";
import {
  selectBodyPart,
  selectBodyType,
  selectCategory,
  selectGender,
  setGender,
} from "../../Redux/slices/userSlice";

import { BsFillArrowUpCircleFill } from "react-icons/bs";

const DetailsScreen = () => {
  useAddHook(
    "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8409127525633466"
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const gender = useSelector(selectGender);
  const category = useSelector(selectCategory);
  const bodyPart = useSelector(selectBodyPart);
  const bodyType = useSelector(selectBodyType);
  const [isAll, setIsAll] = useState(false);
  const [exercises, setExercises] = useState([]);
  const [pagination, setPagination] = useState([]);


  useEffect(() => {
    window.scrollTo(0, 0);
    getData();
  }, [gender, category, isAll]);

  const getData = async (apiUrl = "") => {
    if (apiUrl == "") {
      let all = "";
      if (isAll) {
        all = "?data=all";
      }
      apiUrl = `${base_url}/api/exercises/${gender}/${category}/${bodyPart}/${bodyType}${all}`;
    }
    let res = await fetch(apiUrl, {
      method: "GET",
      headers: new Headers({ "content-type": "application/json" }),
    });
    let response = await res.json();
    if (response?.status === "success") {
      console.log("response?.exercises", response?.exercises);
      setExercises(response?.exercises?.data);
      setPagination(response?.exercises?.links);
    }
  };






  const handle = (event) => {
    document
      .getElementById(`category${event.target.value}`)
      .scrollIntoView({ behavior: "smooth" });
    // window.location.href = `#category${event.target.value}`;
  };

  const handleIsAll = (event) => {
    setIsAll(event.target.value == "all" ? true : false);
  };

  let active = 2;
  let items = [];
  for (let number = 1; number <= 5; number++) {
    items.push(
      <Pagination.Item key={number} active={number === active}>
        {number}
      </Pagination.Item>
    );
  }

  const [visible, setVisible] = useState(false)

  // const toggleVisible = () => {
  //   const scrolled = document.documentElement.scrollTop;
  //   if (scrolled > 300) {
  //     setVisible(true)
  //   }
  //   else if (scrolled <= 300) {
  //     setVisible(false)
  //   }
  // };

  // const scrollToTop = () => {
  //   window.scrollTo({
  //     top: 0,
  //     behavior: 'smooth'

  //   });
  // };

  // window.addEventListener('scroll', toggleVisible);
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 300) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <div className="position-relative">



        <BsFillArrowUpCircleFill onClick={() => {
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }}
          size={50}
          className={`scroll-to-top-btn  ${showButton ? 'visible' : 'hidden'}  m-3 cursor-pointer`}
        />

        {/* 
        <div className=" position-fixed bottom-0 end-0 translate-middle-y" style={{ zIndex: '9999999999999' }}>


        </div> */}


        {exercises?.length > 0 ? (
          <>
            <section
              className="bg-light-blue br-10 position-relative overflowY-scroll"
              style={{
                overflowX: "hidden"
              }}
            >
              <div
                className="bg-light-blue p-3 top-0"
                style={{ zIndex: 1000 }}
              >
                <div className="row gy-2 gy-sm-0 align-items-center mb-2">
                  <div className="col-md-6">
                    <h1 className="text-blue l-spacing fw-500 text-capitalize m-0">
                      {bodyPart}
                    </h1>
                  </div>

                </div>
                <div className="row g-3 g-md-0 align-items-center">
                  <div className="col-md me-md-auto">
                    <div className="row align-items-center py-md-0 py-3 justify-content-center">
                      <div className="col-auto">
                        <h4
                          className="text-blue m-0"
                          onClick={() =>
                            dispatch(setGender("male") + navigate("/"))
                          }
                        >
                          {gender == "male" ? (
                            <i className="fa-regular fa-circle-check me-2" />
                          ) : (
                            <i className="fa-regular fa-circle me-2" />
                          )}
                          Male
                        </h4>
                      </div>
                      <div className="col-auto me-md-auto">
                        <h4
                          className="text-blue m-0"
                          onClick={() =>
                            dispatch(setGender("female")) + navigate("/")
                          }
                        >
                          {gender == "female" ? (
                            <i className="fa-regular fa-circle-check me-2" />
                          ) : (
                            <i className="fa-regular fa-circle me-2" />
                          )}
                          Female
                        </h4>
                      </div>

                      <div className="col-md-6 mt-3 mt-md-0 text-end">
                        <Form.Select
                          className="w-100"
                          aria-label="Default select example"
                          onChange={handle}
                        >
                          <option selected disabled>
                            Open this select menu
                          </option>
                          {exercises?.map((item) => {
                            return (
                              <>
                                <option value={item?.id}>{item?.title}</option>
                              </>
                            );
                          })}
                        </Form.Select>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
              <div className="p-3">
                {exercises?.map((item) => {
                  return (
                    <>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="row shadow-effect p-2 mt-2 rounded-3">
                            <div id={`category${item.id}`} className="col-md-12 ">
                              <p className="s-29 l-spacing text-blue">
                                {item?.title}
                                <span className="s-22">
                                  <i className="ms-3 fa-regular fa-copy"></i>
                                </span>
                              </p>

                              <p className="s-18">
                                <span className="fw-600"> Difficulties :</span>{" "}
                                <span> {item?.difficulty}</span>
                              </p>
                            </div>
                            {item?.exercise_media?.length > 0 && (
                              <>
                                {item?.exercise_media?.map((item) => {
                                  return (
                                    <div className="col-md-6 mt-3">
                                      {imagesExtention.includes(
                                        item?.file_extension
                                      ) == true ? (
                                        <img
                                          width="100%"
                                          src={item?.image}
                                          alt={item?.image}
                                        />
                                      ) : (
                                        <video
                                          controls
                                          width="100%"
                                          height="100%"
                                        >
                                          <source
                                            type="video/mp4"
                                            src={item?.image}
                                          />
                                        </video>
                                      )}
                                    </div>
                                  );
                                })}
                              </>
                            )}
                          </div>
                        </div>

                        <div className="col-md-12">
                          <ol
                            className="steps-list"
                            style={{ margin: "4rem 0 1rem" }}
                          >
                            {item?.description_points?.map((description) => {
                              return <li>{description}</li>;
                            })}
                          </ol>
                        </div>
                      </div>
                    </>
                  );
                })}

                <div className="row align-items-center justify-content-center">
                  <div className="col-auto">
                    <div className="d-flex justify-content-end">
                      <Pagination className="m-0">
                        {pagination?.map((item, i) => {
                          return (
                            <>
                              {i == 0 ? (
                                <Pagination.Prev
                                  onClick={() => getData(item?.url)}
                                />
                              ) : pagination?.length - 1 == i ? (
                                <Pagination.Next
                                  onClick={() => getData(item?.url)}
                                />
                              ) : (
                                <>
                                  {item?.label?.includes("...") ||
                                    item?.active ||
                                    (i >= 0 && i <= 2) ||
                                    (i >= pagination?.length - 3 &&
                                      i <= pagination?.length - 1) ? (
                                    <Pagination.Item
                                      active={item?.active}
                                      onClick={() => getData(item?.url)}
                                    >
                                      {item?.label}
                                    </Pagination.Item>
                                  ) : (
                                    ""
                                  )}
                                </>
                              )}
                            </>
                          );
                        })}
                      </Pagination>
                      <br />
                    </div>
                  </div>
                </div>
              </div>


            </section>

          </>
        ) : (
          <>
            <div className="bg-light-blue br-10 p-5 text-center">
              <h1></h1>
            </div>
          </>
        )}
      </div>

    </>
  );
};

export default DetailsScreen;
