import {createSlice} from '@reduxjs/toolkit';

const userSlice = createSlice({
    name:"user",
    initialState:{
        gender: "male",
        category: "",
        bodyPart: "",
        bodyType: "",
    },
    reducers:{
        setGender: (state, action) => {
            state.gender = action.payload;
        },
        setCategory: (state, action) => {
            state.category = action.payload;
        },
        setBodyPart: (state, action) => {
            state.bodyPart = action.payload;
        },
        setBodyType: (state, action) => {
            state.bodyType = action.payload;
        },
    },
});

export const{
    setGender,
    setCategory,
    setBodyPart,
    setBodyType,
} = userSlice.actions;

export const selectGender  = (state) => state.user.gender;
export const selectCategory  = (state) => state.user.category;
export const selectBodyPart  = (state) => state.user.bodyPart;
export const selectBodyType  = (state) => state.user.bodyType;

export default userSlice.reducer;
